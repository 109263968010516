<template>
  <table-view
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="姓名、学号、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'stuStatus','college','major','keyword']"
        :insert-select-all="['stuStatus']"
        @on-search="renderTable(1)">
        <el-form-item label="学制" label-width="60px">
          <el-select v-model="queryInfo.lengthOfSchooling" clearable size="small">
            <el-option
              v-for="{paramName,paramValue} in $store.state.systemParam.lengthOfSchoolingType"
              :key="paramValue"
              :label="paramName"
              :value="Number(paramValue)" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button type="success" size="small" @click="()=>visibleDIYExportDialog=true">导出学生数据</el-button>
        <upload-excel download-title="下载老生导入模板"
                      import-title="批量导入老生"
                      :permit-key="['stu:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importOldStudent"
                      @on-download="$http.exportExcelHttp.exportOldStudentTemplate()"
                      @on-success="renderTable(1)" />
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="40" />
      <el-table-column prop="examineNo" label="录取编号" width="90" />
      <el-table-column prop="stuNo" label="学号" width="150" align="center">
        <template v-slot="{row}">
          {{ row.stuNo ? row.stuNo : (row.stuStatus === 0 ? '未注册' : '') }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" width="120">
        <template v-slot="{row}">
          <!-- showSubDialog的title属性没有上,借用来传递专业信息  -->
          <el-button type="text" @click="showSubDialog('visibleOnlyReadDialog','onlyReadDialogRef',row.id,row)">
            {{ row.stuName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="学籍状态" width="110">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="60" align="center">
        <template v-slot="{row}">
          {{ Number(row.sexType) === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="150" />
      <el-table-column prop="managerUserName" label="辅导员" width="100" />
      <el-table-column prop="className" label="班级" width="150">
        <template v-slot="{row}">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="campusName" label="校区名称" width="100" />
      <el-table-column prop="apartmentName" label="宿舍" width="150">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo"
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
          <span class="font-grey" v-else-if="row.stuStatus===2">已毕业</span>
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" width="200" label="备注" />
      <el-table-column label="操作" width="220" fixed="right">
        <template v-slot="{ row }">
          <el-button
            v-permission="['stu:edit']"
            type="primary"
            size="mini"
            plain
            @click="showStatusDialog(row)"
          >设置学籍状态
          </el-button>
          <el-button
            v-permission="['stu:edit']"
            type="primary"
            size="mini"
            @click="showDialog(row.id,`${row.stuName}(${row.stuNo?row.stuNo:row.examineNo})`)"
          >编辑
          </el-button>
          <!--  不能删除学籍信息  -->
          <el-button
            v-permission="['stu:del']"
            v-if="false"
            size="mini"
            @click="del(deleteStudent,row.id,`${row.stuName}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  设置学籍状态  -->
    <set-student-status ref="stuStatusDialogRef" :visible.sync="visibleStatusDialog" v-if="visibleStatusDialog"
                        @on-close="renderTable" />
    <student-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <!--  查看学生信息 dialog  -->
    <student-only-read-dialog ref="onlyReadDialogRef" :visible.sync="visibleOnlyReadDialog"
                              v-if="visibleOnlyReadDialog" />
    <!--    自定义导出字段 dialog -->
    <DialogExportStuSelect :visible.sync="visibleDIYExportDialog" v-if="visibleDIYExportDialog"
                           :query-info="queryInfo" />
  </table-view>
</template>

<script>
import { getStudentList, deleteStudent } from '@/api/student'
import setStudentStatus from './dialog/setStudentStatus.vue'
import tableView from '@/vue/mixins/table-view'
import StudentDialog from '@/views/pages/recruitManage/import-student/component/studentDialog.vue'
import StudentOnlyReadDialog from '@/views/pages/recruitManage/import-student/component/studentOnlyReadDialog.vue'
import DialogExportStuSelect from '@/components/common/components/DialogExportStuSelect.vue'

export default {
  components: {
    StudentOnlyReadDialog,
    StudentDialog,
    setStudentStatus,
    DialogExportStuSelect
  },
  mixins: [tableView],
  data () {
    return {
      visibleDIYExportDialog: false, // 自定义字段导出学生
      visibleOnlyReadDialog: false, // 查看学生信息
      visibleStatusDialog: false,
      queryInfo: {
        lengthOfSchooling: null,
        collegeId: '',
        majorId: '',
        stuStatus: null, // （0——未注册，1——在读，2——毕业）
        grade: new Date().getFullYear()
      }
    }
  },
  mounted () {
    this.getStuStatus()
  },
  methods: {
    deleteStudent, // 删除api
    // 获取学生列表
    async renderTable (pageNum) {
      await this.getTableData(getStudentList, pageNum)
    },
    //  修改学籍信息
    showStatusDialog (row) {
      this.visibleStatusDialog = true
      this.$nextTick(() => {
        Object.keys(this.$refs.stuStatusDialogRef.formData).forEach(key => {
          this.$refs.stuStatusDialogRef.formData[key] = row[key]
        })
        this.$refs.stuStatusDialogRef.title = `-${row.stuName}(${row.stuNo ? row.stuNo : row.examineNo})`
        this.$refs.stuStatusDialogRef.setInitData()
      })
    }
  }
}
</script>
