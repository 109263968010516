<template>
  <el-dialog
    class='addStudent-dialog'
    :title='`修改学生学籍状态${title}`'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='520px'
    :before-close='cancel'>
    <el-form
      ref='form'
      :model='formData'
      :rules='rules'
      label-width='80px'
    >
      <el-form-item label='学籍状态' prop='stuStatus'>
        <el-select v-model='formData.stuStatus'>
          <el-option
            v-for='{paramName, paramValue} in $store.state.systemParam.stuStatus'
            :label='paramName'
            :value='Number(paramValue)'
            :key='paramValue' />
        </el-select>
      </el-form-item>
      <el-form-item label='备注' prop='remarks'>
        <el-input type='textarea' v-model='formData.remarks' placeholder='请输入备注' />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type='primary' @click="save(`${title}的学籍状态`,addStudent,'修改成功')">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addStudent } from '@/api/student'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  props: {
    setData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {
        id: '',
        stuStatus: '',
        remarks: ''
      },
      stuStatus: [],
      rules: {
        stuStatus: [
          {
            required: true,
            message: '请选择学籍状态',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    addStudent // 保存api
  }
}
</script>
